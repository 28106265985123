var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _c("div", { staticClass: "member-login" }, [
      _c("h3", [_vm._v("Mitgliederportal Anmeldung")]),
      _vm._v(" "),
      _c("div", { staticClass: "member-input" }, [
        _c("div", { staticClass: "input-wrap" }, [
          _c("label", [
            _vm._v("E-Mail Adresse\n          "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.memberData.Writable.Mitgliedemail,
                  expression: "memberData.Writable.Mitgliedemail"
                }
              ],
              attrs: { type: "email" },
              domProps: { value: _vm.memberData.Writable.Mitgliedemail },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(
                    _vm.memberData.Writable,
                    "Mitgliedemail",
                    $event.target.value
                  )
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "input-wrap" }, [
          _c("label", [
            _vm._v("Passwort\n          "),
            _c("input", {
              directives: [
                {
                  name: "model",
                  rawName: "v-model",
                  value: _vm.memberData.password,
                  expression: "memberData.password"
                }
              ],
              attrs: { type: "password" },
              domProps: { value: _vm.memberData.password },
              on: {
                input: function($event) {
                  if ($event.target.composing) {
                    return
                  }
                  _vm.$set(_vm.memberData, "password", $event.target.value)
                }
              }
            })
          ])
        ]),
        _vm._v(" "),
        _c(
          "button",
          { staticClass: "member-button", on: { click: _vm.login } },
          [_vm._v("Anmelden")]
        )
      ])
    ]),
    _vm._v(" "),
    _vm.errorMsg
      ? _c("div", { staticClass: "member-notice" }, [
          _c("p", [_vm._v(_vm._s(_vm.errorMsg))])
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }