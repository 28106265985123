import Vue from 'vue'
import App from './vue/App.vue'
import  '../../scss/app.scss'
jQuery(document).ready(function ($) {

  var app = new Vue({
    render: (h) => h(App)
  }).$mount('#app')


});


