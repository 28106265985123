<template>
  <div>
    <div v-if="error">
      <div class="member-login">
        <MemberLogin v-if="resetPassword !== true" :error="error" @login-success="retryLogin"></MemberLogin>
        <ResetPassword v-if="resetPassword === true" :error="error"
                       @reset-success="resetPassword = false"></ResetPassword>
        <a v-if="resetPassword !== true" @click="resetPassword = true">Passwort vergessen?</a>
      </div>
    </div>
    <div v-if="memberData" class="nf-form-content">
      <div class="member-readonly">
        <h3>Deine Mitgliedschaft</h3>
        <hr>
        <h4>Beiträge</h4>
        <ul class="beitraege">
          <li class="beitrag" v-for="b of getBeitraege()">
            <h5>{{ b.Name }}</h5>
            <p><strong>Betrag pro Quartal:</strong> {{ formatValue(b.Betrag) }}€</p>
            <p><strong>Mitgliedschaft ab:</strong> {{ formatValue(b.ZahlungAb) }}</p>
            <p><strong>Mitgliedschaft bis:</strong> {{ formatValue(b.ZahlungBis) }}</p>
          </li>
        </ul>
        <p><strong>Zahlungsart:</strong> {{ memberData.Readonly.Zahlungsart }}</p>
        <p><strong>Fälligkeit:</strong> {{ memberData.Readonly.Faelligkeit }}</p>
        <p><strong>Vereins Eintritt:</strong> {{ memberData.Readonly.VereinsEintritt }}</p>
      </div>
      <div class="member-writable">
        <h3>Ihre Daten</h3>
        <hr>
        <h4>Persönliche Daten</h4>
        <div class="member-input">
          <div class="input-wrap">
            <label>Anrede
              <select class="ninja-forms-field" v-model="memberData.Writable.Anrede">
                <option value="Herr">Herr</option>
                <option value="Frau">Frau</option>
              </select>
            </label>
          </div>
        </div>
        <div class="member-input">
          <div class="member-section">
            <div class="member-row">
              <div class="input-wrap">
                <label>
                  Vorname
                  <input class="ninja-forms-field" v-model="memberData.Writable.Vorname">
                </label>
              </div>
              <div class="input-wrap">
                <label>
                  Nachname
                  <input v-model="memberData.Writable.Name">
                </label>
              </div>
            </div>
            <!--            <div class="input-wrap">-->
            <!--              <label>-->
            <!--                Geburtstag-->
            <!--                <input v-model="memberData.Writable.Geburtstag">-->
            <!--              </label>-->
            <!--            </div>-->
            <div class="member-row">
              <div class="input-wrap">
                <label>
                  Strasse
                  <input v-model="memberData.Writable.Strasse">
                </label>
              </div>
              <div class="input-wrap">
                <label>
                  Hausnummer
                  <input v-model="memberData.Writable.Hausnummer">
                </label>
              </div>
            </div>
            <div class="member-row">
              <div class="input-wrap">
                <label>
                  Postleitzahl
                  <input v-model="memberData.Writable.Plz">
                </label>
              </div>
              <div class="input-wrap">
                <label>
                  Ort
                  <input v-model="memberData.Writable.Ort">
                </label>
              </div>
            </div>
            <div class="input-wrap">
              <label>
                Telefon
                <input v-model="memberData.Writable.Telefon">
              </label>
            </div>
            <div class="input-wrap">
              <label>
                E-Mail
                <input v-model="memberData.Writable.Mitgliedemail">
              </label>
            </div>
          </div>
          <div class="member-section">
            <h4>Bankverbindung</h4>
            <div class="input-wrap">
              <label>
                IBAN
                <input v-model="memberData.Writable.Iban">
              </label>
            </div>
            <div class="input-wrap">
              <label>
                Bic
                <input v-model="memberData.Writable.Bic">
              </label>
            </div>
          </div>
          <div class="member-section">
            <h4>Sparten</h4>
            <div class="input-wrap">
              <div class="select-container" v-for="[i,sparte] in selectedSparten.entries()">
                <select>
                  <option v-for="s in sparten" :selected="sparte.Name === s.label" :name="s.label">{{
                      s.label
                    }}
                  </option>
                </select>
                <span class="remove-button" @click="deselectItem(i, $event)">Von Dieser Sparte abmelden</span>
              </div>
              <div class="select-container" v-for="[i,sparte] in newSparten.entries()">
                <select @change="selectNew(i,$event)">
                  <option v-for="s in sparten" :selected="sparte.Name === s.label" :name="s.label">{{
                      s.label
                    }}
                  </option>
                </select>
                <span class="remove-button" @click="removeItem(i, 'newSparten')">Diese Sparte abmelden</span>
              </div>
              <button class="add-sparte" @click="addSparte">Sparte hinzufügen</button>
            </div>
            <div class="deselected-items" v-if="deselectedSparten.length > 0">
              <h5>Sie werden von folgenden Kursen abgemeldet</h5>
              <div v-for="[i,s] of deselectedSparten.entries()">{{ s.Name }} <span class="reset-item"
                                                                                   @click="reAddSparte(i,s)">rückgängig</span>
              </div>
            </div>
          </div>
          <div class="member-section">
            <h4>Zahlungsdaten</h4>
            <div class="member-input">
              <div class="input-wrap">
                <label>Anrede
                  <select v-model="memberData.Writable.Zanrede">
                    <option value="Herr">Herr</option>
                    <option value="Frau">Frau</option>
                  </select>
                </label>
              </div>
            </div>
            <div class="member-row">
              <div class="input-wrap">
                <label>
                  Vorname
                  <input v-model="memberData.Writable.Zvorname">
                </label>
              </div>
              <div class="input-wrap">
                <label>
                  Nachname
                  <input v-model="memberData.Writable.Zname">
                </label>
              </div>
            </div>
            <div class="member-row">
              <div class="input-wrap">
                <label>
                  Strasse
                  <input v-model="memberData.Writable.Zstrasse">
                </label>
              </div>
              <div class="input-wrap">
                <label>
                  Hausnummer
                  <input v-model="memberData.Writable.Zhausnummer">
                </label>
              </div>
            </div>
            <div class="member-row">
              <div class="input-wrap">
                <label>
                  Postleitzahl
                  <input v-model="memberData.Writable.Zplz">
                </label>
              </div>
              <div class="input-wrap">
                <label>
                  Ort
                  <input v-model="memberData.Writable.Zort">
                </label>
              </div>
            </div>
          </div>
        </div>
      </div>
      <button class="member-button" @click="saveData">{{ buttonMsg }}</button>
      <div v-if="requestNotice" class="member-notice notice-success">
        <p>{{ requestNotice }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
import MemberLogin from "./MemberLogin.vue";
import ResetPassword from "./ResetPassword.vue";

export default {
  name: "App",
  components: {ResetPassword, MemberLogin},
  data() {
    return {
      data: null,
      resetPassword: false,
      memberData: null,
      buttonMsg: 'Speichern',
      selectedSparten: null,
      deselectedSparten: [],
      newSparten: [],
      loading: true,
      error: null,
      requestNotice: false,
      sparten: [
        {
          value: 'Aerobic',
          label: 'Aerobic'
        },
        {
          value: 'Amb. Herzgruppe',
          label: 'Amb. Herzgruppe'
        },
        {
          value: 'Badminton',
          label: 'Badminton'
        },
        {
          value: 'Basketball',
          label: 'Basketball'
        },
        {
          value: 'Behindertensport',
          label: 'Behindertensport'
        },
        {
          value: 'Bouldern',
          label: 'Bouldern'
        },
        {
          value: 'Boule',
          label: 'Boule'
        },
        {
          value: 'Bowling',
          label: 'Bowling'
        },
        {
          value: 'Bridge',
          label: 'Bridge'
        },
        {
          value: 'Bujinkan Budo Taijutsu',
          label: 'Bujinkan Budo Taijutsu'
        },
        {
          value: 'Cheerleader',
          label: 'Cheerleader'
        },
        {
          value: 'Dance Studios',
          label: 'Dance Studios'
        },
        {
          value: 'Fechten-Ligade',
          label: 'Fechten-Ligade'
        },
        {
          value: 'Fitness & Aerobic',
          label: 'Fitness & Aerobic'
        },
        {
          value: 'Fitness & Gesundheitssport',
          label: 'Fitness & Gesundheitssport'
        },
        {
          value: 'Fitness & Tennis',
          label: 'Fitness & Tennis'
        },
        {
          value: 'Fitness-Studio',
          label: 'Fitness-Studio'
        },
        {
          value: 'Gesundheitssport',
          label: 'Gesundheitssport'
        },
        {
          value: 'Judo',
          label: 'Judo'
        },
        {
          value: 'Ju-Jutsu',
          label: 'Ju-Jutsu'
        },
        {
          value: 'Karate',
          label: 'Karate'
        },
        {
          value: 'Kickboxen',
          label: 'Kickboxen'
        },
        {
          value: 'Klettern',
          label: 'Klettern'
        },
        {
          value: 'Leichtathletik',
          label: 'Leichtathletik'
        },
        {
          value: 'Nordic Walking',
          label: 'Nordic Walking'
        },
        {
          value: 'Parkour',
          label: 'Parkour'
        },
        {
          value: 'Radsport',
          label: 'Radsport'
        },
        {
          value: 'Schach',
          label: 'Schach'
        },
        {
          value: 'Sportkegeln',
          label: 'Sportkegeln'
        },
        {
          value: 'Tanzsport',
          label: 'Tanzsport'
        },
        {
          value: 'Tennis',
          label: 'Tennis'
        }, {
          value: 'Tischtennis',
          label: 'Tischtennis'
        },
        {
          value: 'Triathlon',
          label: 'Triathlon'
        },
        {
          value: 'Turnen',
          label: 'Turnen'
        },
        {
          value: 'Kunstturnen Turnteam',
          label: 'Kunstturnen Turnteam'
        },
        {
          value: 'Aerobicturnen',
          label: 'Aerobicturnen'
        },
        {
          value: 'Velo-Freunde',
          label: 'Velo-Freunde'
        },
        {
          value: 'Volleyball',
          label: 'Volleyball'
        },
        {
          value: 'Yoga',
          label: 'Yoga'
        },
      ],

    }
  },
  async mounted() {
    let data = await this.checkLogin();
    this.data = {
      MemberData: {Writable: {}}
    };
    if (!data) {
      return;
    }
    this.error = null;
    this.memberData = data.data.MemberData
    if (Array.isArray(data.data.MemberData.Writable.Sparten.Sparte)) {
      this.selectedSparten = data.data.MemberData.Writable.Sparten.Sparte
    } else {
      this.selectedSparten = [data.data.MemberData.Writable.Sparten.Sparte];
    }
  },
  methods: {
    selectNew(index, event) {
      this.newSparten[index].Name = event.currentTarget.value;
    },
    async retryLogin() {
      let data = await this.checkLogin();
      if (data && data.data && data.data.MemberData) {
        this.error = null;
        this.memberData = data.data.MemberData;
        if (Array.isArray(data.data.MemberData.Writable.Sparten.Sparte)) {
          this.selectedSparten = data.data.MemberData.Writable.Sparten.Sparte
        } else {
          this.selectedSparten = [data.data.MemberData.Writable.Sparten.Sparte];
        }
        return;
      }

      this.data = {};
      if (!data) {
        return;
      }
    },
    async checkLogin() {
      let url = window.appdata.rest;
      let data = await axios.get(url);
      if (data.data.Errors) {
        if (data.data.Errors.TechErrCode) {
          this.error = 'Ihre Anmeldung ist abgelaufen. Bitte melden Sie sich erneut an.';
          return false;
        }
      }
      if (data.data && data.data.success === false) {
        this.error = 'Ihre Anmeldung ist abgelaufen. Bitte melden Sie sich erneut an.';
        return false;
      }
      this.data = data.data;

      return data;
    },
    async saveData() {
      let data = this.memberData;
      let url = window.appdata.rest;
      data.newSparten = this.newSparten;
      data.deselectedSparten = this.deselectedSparten;
      this.buttonMsg = 'Bitte warten';
      let req = await axios.post(url, data);
      if (req.data.RequestState === 'OK') {
        this.requestNotice = 'Ihre Daten wurde erfolgreich aktualisiert. Sie erhalten eine Bestätigungsmail.'
      }
      this.buttonMsg = 'Speichern';

    },
    formatValue(val) {
      if (val && val.length === 0) {
        return '';
      }
      return val;
    },
    addSparte() {
      this.newSparten.push({
        Name: ''
      });
    },
    removeItem(i, target) {
      this[target].splice(i, 1);
    },
    deselectItem(i) {
      let item = this.selectedSparten[i];
      this.selectedSparten.splice(i, 1);
      this.deselectedSparten.push(item);
    },
    reAddSparte(i, s) {
      this.deselectedSparten.splice(i, 1);
      this.selectedSparten.push(s);
    },
    getBeitraege() {

      if (Array.isArray(this.memberData.Readonly.Beitraege) && this.memberData.Readonly.Beitraege.length === 0) {
        return [];
      }

      if (Array.isArray(this.memberData.Readonly.Beitraege.Beitrag)) {
        return this.memberData.Readonly.Beitraege.Beitrag
      } else {
        return [this.memberData.Readonly.Beitraege.Beitrag]
      }
    }
  },
}
</script>

<style>
.beitraege {
  list-style: none;
  margin: 0;
  padding: 0;
}
</style>