var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", [
    _vm.error
      ? _c("div", [
          _c(
            "div",
            { staticClass: "member-login" },
            [
              _vm.resetPassword !== true
                ? _c("MemberLogin", {
                    attrs: { error: _vm.error },
                    on: { "login-success": _vm.retryLogin }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.resetPassword === true
                ? _c("ResetPassword", {
                    attrs: { error: _vm.error },
                    on: {
                      "reset-success": function($event) {
                        _vm.resetPassword = false
                      }
                    }
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.resetPassword !== true
                ? _c(
                    "a",
                    {
                      on: {
                        click: function($event) {
                          _vm.resetPassword = true
                        }
                      }
                    },
                    [_vm._v("Passwort vergessen?")]
                  )
                : _vm._e()
            ],
            1
          )
        ])
      : _vm._e(),
    _vm._v(" "),
    _vm.memberData
      ? _c("div", { staticClass: "nf-form-content" }, [
          _c("div", { staticClass: "member-readonly" }, [
            _c("h3", [_vm._v("Deine Mitgliedschaft")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("h4", [_vm._v("Beiträge")]),
            _vm._v(" "),
            _c(
              "ul",
              { staticClass: "beitraege" },
              _vm._l(_vm.getBeitraege(), function(b) {
                return _c("li", { staticClass: "beitrag" }, [
                  _c("h5", [_vm._v(_vm._s(b.Name))]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", [_vm._v("Betrag pro Quartal:")]),
                    _vm._v(" " + _vm._s(_vm.formatValue(b.Betrag)) + "€")
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", [_vm._v("Mitgliedschaft ab:")]),
                    _vm._v(" " + _vm._s(_vm.formatValue(b.ZahlungAb)))
                  ]),
                  _vm._v(" "),
                  _c("p", [
                    _c("strong", [_vm._v("Mitgliedschaft bis:")]),
                    _vm._v(" " + _vm._s(_vm.formatValue(b.ZahlungBis)))
                  ])
                ])
              }),
              0
            ),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Zahlungsart:")]),
              _vm._v(" " + _vm._s(_vm.memberData.Readonly.Zahlungsart))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Fälligkeit:")]),
              _vm._v(" " + _vm._s(_vm.memberData.Readonly.Faelligkeit))
            ]),
            _vm._v(" "),
            _c("p", [
              _c("strong", [_vm._v("Vereins Eintritt:")]),
              _vm._v(" " + _vm._s(_vm.memberData.Readonly.VereinsEintritt))
            ])
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "member-writable" }, [
            _c("h3", [_vm._v("Ihre Daten")]),
            _vm._v(" "),
            _c("hr"),
            _vm._v(" "),
            _c("h4", [_vm._v("Persönliche Daten")]),
            _vm._v(" "),
            _c("div", { staticClass: "member-input" }, [
              _c("div", { staticClass: "input-wrap" }, [
                _c("label", [
                  _vm._v("Anrede\n            "),
                  _c(
                    "select",
                    {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberData.Writable.Anrede,
                          expression: "memberData.Writable.Anrede"
                        }
                      ],
                      staticClass: "ninja-forms-field",
                      on: {
                        change: function($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function(o) {
                              return o.selected
                            })
                            .map(function(o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.$set(
                            _vm.memberData.Writable,
                            "Anrede",
                            $event.target.multiple
                              ? $$selectedVal
                              : $$selectedVal[0]
                          )
                        }
                      }
                    },
                    [
                      _c("option", { attrs: { value: "Herr" } }, [
                        _vm._v("Herr")
                      ]),
                      _vm._v(" "),
                      _c("option", { attrs: { value: "Frau" } }, [
                        _vm._v("Frau")
                      ])
                    ]
                  )
                ])
              ])
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "member-input" }, [
              _c("div", { staticClass: "member-section" }, [
                _c("div", { staticClass: "member-row" }, [
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Vorname\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Vorname,
                            expression: "memberData.Writable.Vorname"
                          }
                        ],
                        staticClass: "ninja-forms-field",
                        domProps: { value: _vm.memberData.Writable.Vorname },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Vorname",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Nachname\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Name,
                            expression: "memberData.Writable.Name"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Name },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Name",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "member-row" }, [
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Strasse\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Strasse,
                            expression: "memberData.Writable.Strasse"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Strasse },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Strasse",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Hausnummer\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Hausnummer,
                            expression: "memberData.Writable.Hausnummer"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Hausnummer },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Hausnummer",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "member-row" }, [
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v(
                        "\n                Postleitzahl\n                "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Plz,
                            expression: "memberData.Writable.Plz"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Plz },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Plz",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Ort\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Ort,
                            expression: "memberData.Writable.Ort"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Ort },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Ort",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-wrap" }, [
                  _c("label", [
                    _vm._v("\n              Telefon\n              "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberData.Writable.Telefon,
                          expression: "memberData.Writable.Telefon"
                        }
                      ],
                      domProps: { value: _vm.memberData.Writable.Telefon },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.memberData.Writable,
                            "Telefon",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-wrap" }, [
                  _c("label", [
                    _vm._v("\n              E-Mail\n              "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberData.Writable.Mitgliedemail,
                          expression: "memberData.Writable.Mitgliedemail"
                        }
                      ],
                      domProps: {
                        value: _vm.memberData.Writable.Mitgliedemail
                      },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.memberData.Writable,
                            "Mitgliedemail",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "member-section" }, [
                _c("h4", [_vm._v("Bankverbindung")]),
                _vm._v(" "),
                _c("div", { staticClass: "input-wrap" }, [
                  _c("label", [
                    _vm._v("\n              IBAN\n              "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberData.Writable.Iban,
                          expression: "memberData.Writable.Iban"
                        }
                      ],
                      domProps: { value: _vm.memberData.Writable.Iban },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.memberData.Writable,
                            "Iban",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "input-wrap" }, [
                  _c("label", [
                    _vm._v("\n              Bic\n              "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.memberData.Writable.Bic,
                          expression: "memberData.Writable.Bic"
                        }
                      ],
                      domProps: { value: _vm.memberData.Writable.Bic },
                      on: {
                        input: function($event) {
                          if ($event.target.composing) {
                            return
                          }
                          _vm.$set(
                            _vm.memberData.Writable,
                            "Bic",
                            $event.target.value
                          )
                        }
                      }
                    })
                  ])
                ])
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "member-section" }, [
                _c("h4", [_vm._v("Sparten")]),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "input-wrap" },
                  [
                    _vm._l(_vm.selectedSparten.entries(), function(ref) {
                      var i = ref[0]
                      var sparte = ref[1]
                      return _c("div", { staticClass: "select-container" }, [
                        _c(
                          "select",
                          _vm._l(_vm.sparten, function(s) {
                            return _c(
                              "option",
                              {
                                attrs: { name: s.label },
                                domProps: { selected: sparte.Name === s.label }
                              },
                              [_vm._v(_vm._s(s.label) + "\n                ")]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "remove-button",
                            on: {
                              click: function($event) {
                                return _vm.deselectItem(i, $event)
                              }
                            }
                          },
                          [_vm._v("Von Dieser Sparte abmelden")]
                        )
                      ])
                    }),
                    _vm._v(" "),
                    _vm._l(_vm.newSparten.entries(), function(ref) {
                      var i = ref[0]
                      var sparte = ref[1]
                      return _c("div", { staticClass: "select-container" }, [
                        _c(
                          "select",
                          {
                            on: {
                              change: function($event) {
                                return _vm.selectNew(i, $event)
                              }
                            }
                          },
                          _vm._l(_vm.sparten, function(s) {
                            return _c(
                              "option",
                              {
                                attrs: { name: s.label },
                                domProps: { selected: sparte.Name === s.label }
                              },
                              [_vm._v(_vm._s(s.label) + "\n                ")]
                            )
                          }),
                          0
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          {
                            staticClass: "remove-button",
                            on: {
                              click: function($event) {
                                return _vm.removeItem(i, "newSparten")
                              }
                            }
                          },
                          [_vm._v("Diese Sparte abmelden")]
                        )
                      ])
                    }),
                    _vm._v(" "),
                    _c(
                      "button",
                      {
                        staticClass: "add-sparte",
                        on: { click: _vm.addSparte }
                      },
                      [_vm._v("Sparte hinzufügen")]
                    )
                  ],
                  2
                ),
                _vm._v(" "),
                _vm.deselectedSparten.length > 0
                  ? _c(
                      "div",
                      { staticClass: "deselected-items" },
                      [
                        _c("h5", [
                          _vm._v("Sie werden von folgenden Kursen abgemeldet")
                        ]),
                        _vm._v(" "),
                        _vm._l(_vm.deselectedSparten.entries(), function(ref) {
                          var i = ref[0]
                          var s = ref[1]
                          return _c("div", [
                            _vm._v(_vm._s(s.Name) + " "),
                            _c(
                              "span",
                              {
                                staticClass: "reset-item",
                                on: {
                                  click: function($event) {
                                    return _vm.reAddSparte(i, s)
                                  }
                                }
                              },
                              [_vm._v("rückgängig")]
                            )
                          ])
                        })
                      ],
                      2
                    )
                  : _vm._e()
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "member-section" }, [
                _c("h4", [_vm._v("Zahlungsdaten")]),
                _vm._v(" "),
                _c("div", { staticClass: "member-input" }, [
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("Anrede\n                "),
                      _c(
                        "select",
                        {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.memberData.Writable.Zanrede,
                              expression: "memberData.Writable.Zanrede"
                            }
                          ],
                          on: {
                            change: function($event) {
                              var $$selectedVal = Array.prototype.filter
                                .call($event.target.options, function(o) {
                                  return o.selected
                                })
                                .map(function(o) {
                                  var val = "_value" in o ? o._value : o.value
                                  return val
                                })
                              _vm.$set(
                                _vm.memberData.Writable,
                                "Zanrede",
                                $event.target.multiple
                                  ? $$selectedVal
                                  : $$selectedVal[0]
                              )
                            }
                          }
                        },
                        [
                          _c("option", { attrs: { value: "Herr" } }, [
                            _vm._v("Herr")
                          ]),
                          _vm._v(" "),
                          _c("option", { attrs: { value: "Frau" } }, [
                            _vm._v("Frau")
                          ])
                        ]
                      )
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "member-row" }, [
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Vorname\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Zvorname,
                            expression: "memberData.Writable.Zvorname"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Zvorname },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Zvorname",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Nachname\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Zname,
                            expression: "memberData.Writable.Zname"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Zname },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Zname",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "member-row" }, [
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Strasse\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Zstrasse,
                            expression: "memberData.Writable.Zstrasse"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Zstrasse },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Zstrasse",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Hausnummer\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Zhausnummer,
                            expression: "memberData.Writable.Zhausnummer"
                          }
                        ],
                        domProps: {
                          value: _vm.memberData.Writable.Zhausnummer
                        },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Zhausnummer",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "member-row" }, [
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v(
                        "\n                Postleitzahl\n                "
                      ),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Zplz,
                            expression: "memberData.Writable.Zplz"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Zplz },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Zplz",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "input-wrap" }, [
                    _c("label", [
                      _vm._v("\n                Ort\n                "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.memberData.Writable.Zort,
                            expression: "memberData.Writable.Zort"
                          }
                        ],
                        domProps: { value: _vm.memberData.Writable.Zort },
                        on: {
                          input: function($event) {
                            if ($event.target.composing) {
                              return
                            }
                            _vm.$set(
                              _vm.memberData.Writable,
                              "Zort",
                              $event.target.value
                            )
                          }
                        }
                      })
                    ])
                  ])
                ])
              ])
            ])
          ]),
          _vm._v(" "),
          _c(
            "button",
            { staticClass: "member-button", on: { click: _vm.saveData } },
            [_vm._v(_vm._s(_vm.buttonMsg))]
          ),
          _vm._v(" "),
          _vm.requestNotice
            ? _c("div", { staticClass: "member-notice notice-success" }, [
                _c("p", [_vm._v(_vm._s(_vm.requestNotice))])
              ])
            : _vm._e()
        ])
      : _vm._e()
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }