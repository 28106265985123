<template>
  <div>
    <div class="member-login">
      <h3>Mitgliederportal Anmeldung</h3>
      <div class="member-input">
        <div class="input-wrap">
          <label>E-Mail Adresse
            <input type="email" v-model="memberData.Writable.Mitgliedemail">
          </label>
        </div>
        <div class="input-wrap">
          <label>Passwort
            <input type="password" v-model="memberData.password">
          </label>
        </div>
        <button class="member-button" @click="login">Anmelden</button>
      </div>
    </div>
    <div class="member-notice" v-if="errorMsg">
      <p>{{ errorMsg }}</p>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import get from "lodash.get"

export default {
  name: "MemberLogin",
  props:['error'],
  data() {
    return {
      errorMsg: this.error,
      memberData: {
        Writable: {
          Mitgliedemail: ''
        },
        password: ''
      },
    }
  },
  methods: {
    async login() {
      this.errorMsg = null;
      let url = window.appdata.login;
      let data = this.memberData;
      let req = await axios.post(url, data);
      if (req.data.success) {
        this.$emit('login-success');
      }
      if (!req.data.success) {
        this.handleError(req.data.data);
      }
    },
    handleError(data) {
      let msg = get(data, 'Errors.UserErrMsg', null);
      this.errorMsg = msg;
    }
  },
}
</script>

<style scoped>

</style>